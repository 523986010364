/**
 * 房间聊天API
 * */
import { gameplusApi } from '@/util/http';

export default {

  /**
   * 获取群组信息
   * https://git.woa.com/KnockKnock/Backend/proto/blob/master/group_base_srv.proto
   * @param {Object} param
   * @param {Number} param.gid kk房间id
   */
  getKKGroupInfo({
    gid,
  }) {
    return gameplusApi.request({
      url: '/imapi.GroupBaseSrv/GetGroupInfo',
      data: {
        gid,
      },
      options: {
        errorHandler: false,
      },
    });
  },
};
