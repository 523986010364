<template>
  <div v-show="rcmdItems.length">
    <div class="post-rcmd-title">
      相关内容
    </div>
    <div
      v-for="(item, index) in rcmdItems"
      :key="index"
    >
      <div
        @click="handleJump(item)"
        class="post-rcmd-item-container"
      >
        <div class="post-rcmd-item-title-wrapper">
          <div class="post-rcmd-item-title">
            {{ item.title }}
          </div>
          <div>
            <span
              v-if="item.tag"
              class="post-rcmd-item-tag"
            >
              {{ item.tag }}
            </span>
            <span class="post-rcmd-item-subtitle">
              {{ item.subTitle }}
            </span>
          </div>
        </div>
        <div class="post-rcmd-item-pic-wrapper">
          <img
            :src="item.cover"
            class="post-rcmd-item-pic"
            alt="picture"
          >
          <img
            v-if="item.videoDuration"
            src="./img/video_play_circle.png"
            class="post-rcmd-item-video-play"
            alt="play video"
          >
          <div
            v-if="item.videoDuration"
            class="post-rcmd-item-video-duration"
          >
            {{ item.videoDuration }}
          </div>
        </div>
      </div>
      <div class="post-rcmd-item-line" />
    </div>
  </div>
</template>

<script>
import groupApi from '@/api/group';
import chatApi from '@/api/chat';
import { openView } from '@/jsbridge';
import { translateNumToText, translateSecondsToText } from '@/util/util';
import { parseInt } from 'lodash';
import { checkEnv } from '@/util/browser-env';
import launchApp from '@/util/launchApp';
import chatCover from './img/chat_cover.png';
import postDefaultCover from './img/post_default_cover.png';

const maxRcmdFeedsCount = 4;

export default {
  name: 'PostRcmd',
  props: {
    title: {
      type: String,
      default() {
        return '';
      },
    },
    postId: {
      type: [String, Number],
      default() {
        return '0';
      },
    },
    groupId: {
      type: [String, Number],
      default() {
        return '0';
      },
    },
    groupName: {
      type: String,
      default() {
        return '';
      },
    },
    kkGid: {
      type: [String, Number],
      default() {
        return '0';
      },
    },
  },
  data() {
    return {
      env: checkEnv(),
      rcmdItems: [],
      chatGroupInfo: null,
    };
  },
  mounted() {
    this.fetchChatGroupInfo();
    this.fetchRcmdFeedList();
  },
  methods: {
    handleJump(item) {
      if (item.isChat) {
        // 聊天入口
        if (this.env.isApp) {
          openView({
            options: item.url,
          });
        } else {
          launchApp({
            scheme: item.url,
          });
        }
        return;
      }
      // 帖子跳转
      window.location.href = item.url;
    },
    fetchRcmdFeedList() {
      const gid = parseInt(this.groupId, 10);
      groupApi.getGroupFeedList({ groupId: gid, type: 1, sortType: 2 }).then((result) => {
        if (result.ret !== 0) {
          return;
        }
        for (const feedItem of result.cardList) {
          const post = feedItem.postData;
          if (post.id !== this.postId) { // 和当前帖子去重
            let duration;
            let coverUrl;
            if (post.subType === 2) { // 视频贴
              [coverUrl] = post.video;
              duration = translateSecondsToText(post.video.duration);
            } else {
              coverUrl = post.pictureList?.info[0]?.url;
            }
            coverUrl ??= postDefaultCover;
            this.rcmdItems.push({
              id: post.id,
              title: post.title,
              subTitle: this.getPostSubTitle(post),
              cover: coverUrl,
              url: post.schemeUrl,
              videoDuration: duration,
              tag: null,
            });
          }
          if (this.rcmdItems.length >= maxRcmdFeedsCount) {
            break;
          }
        }
        const kGid = parseInt(this.kkGid, 10);
        if (kGid) {
          this.rcmdItems.push(this.getChatInfo());
        }
      });
    },
    fetchChatGroupInfo() {
      const kGid = parseInt(this.kkGid, 10);
      if (kGid) {
        chatApi.getKKGroupInfo({ gid: kGid }).then((result) => {
          if (result.ret === 0) {
            this.chatGroupInfo = result.info;
          }
          if (this.rcmdItems.length > 0) {
            // 已拉取推荐帖子列表，更新最后一条的房间信息
            this.rcmdItems.pop();
            this.rcmdItems.push(this.getChatInfo());
          }
        });
      }
    },
    getPostSubTitle(post) {
      if (post.readNum) {
        return `${this.groupName} · ${translateNumToText(post.readNum)}浏览`;
      }
      return this.groupName;
    },
    getChatSubTitle() {
      if (this.chatGroupInfo?.memberNum) {
        return `热聊房间 · ${this.chatGroupInfo.memberNum}人`;
      }
      return '热聊房间';
    },
    getChatInfo() {
      return {
        title: this.groupName,
        subTitle: this.getChatSubTitle(),
        cover: chatCover,
        url: `tgc://flutter?page=home&action=open_group&gid=${this.kkGid}`,
        tag: '热聊',
        isChat: true,
      };
    },
  },
};
</script>

<style lang="scss" src="./scss/index.scss">

</style>
